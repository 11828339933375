<template>
  <div class="paper">
    <el-row>
      <h1>试卷库</h1>
    </el-row>
    <el-row>
      <el-table
          :data="tableData.records"
          style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="props">
            <show-paper :one="props.row"></show-paper>
          </template>
        </el-table-column>
        <el-table-column
            prop="paperTopic"
            label="文章标题"
        >
        </el-table-column>

        <el-table-column
            label="类别"
            width="180"
        >
          <template slot-scope="scope">
            {{ getTagName(scope.row.paperType) }}
          </template>
        </el-table-column>
        <el-table-column
            prop="paperRank"
            width="50"
            label="难度">
        </el-table-column>
        <el-table-column
            prop="tags"
            label="标签信息">
          <template slot-scope="scope">
            <little-tag :text="scope.row.tags"></little-tag>
          </template>
        </el-table-column>
        <el-table-column
            prop="gmtCreate"
            width="150"
            label="创建时间">
        </el-table-column>
      </el-table>
      <el-pagination
          background
          :current-page="tableData.current"
          @current-change="getTableData"
          layout="prev, pager, next"
          :total="tableData.pages">
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
import {getPaperAll} from "@/api/paper";
import LittleTag from "@/components/tags/little-tag";
import ShowPaper from "@/views/paper/common/show-paper";

export default {
  name: "paper",
  components: {ShowPaper, LittleTag},
  data() {
    return {
      tableData: []
    }
  },
  mounted() {
    this.getTableData(1);
  },
  methods: {
    getTableData(number) {
      getPaperAll(number).then(result => {
        console.log(result)
        this.tableData = result.data.paper;
      })
    },
    getTagName(type) {
      if (type === 1) {
        return "选择题"
      } else if (type === 2) {
        return "判断题"
      } else if (type === 3) {
        return "填空题"
      } else {
        return "简答题"
      }
    }
  }
}
</script>

<style scoped>
.paper{
  width: 100%;
  background-color: #fff;
  /*padding: 10px;*/
}
</style>