import service from "@/utils/request";

/**
 * 获取所有的试卷
 * @param current
 * @returns {AxiosPromise}
 */
export function getPaperAll(current) {
    return service(
        {
            url: 'paper/getPaperAll',
            method: 'post',
            params: {
                current
            }
        })
}

/**
 * 根据用户的id 来展示所有的试题
 * @param id
 * @returns {AxiosPromise}
 */
export function getPaperById(id) {
    return service(
        {
            url: 'paper/getPaperById',
            method: 'post',
            params: {
                id
            }
        })
}

/**
 * 添加试题
 * @returns {AxiosPromise}
 * @param paper
 */
export function addPaper(paper) {
    return service(
        {
            url: 'paper/addPaper',
            method: 'post',
            header:{
                "Content-Type":"application/json" //如果写成contentType会报错
            },
            data:paper
        })
}

/**
 * 更新试题
 * @param paper
 * @returns {*}
 */
export function updatePaper(paper) {
    return service(
        {
            url: 'paper/updatePaper',
            method: 'post',
            header:{
                "Content-Type":"application/json" //如果写成contentType会报错
            },
            data:paper
        })
}

/**
 * 删除试题
 * @param id
 * @returns {AxiosPromise}
 */
export function deletePaper(id) {
    return service(
        {
            url: 'paper/deletePaper',
            method: 'post',
            params:{
                id
            }
        })
}
